export const getActivePatrimonial = () => {
  return ['Nome', 'Tipo', 'Selável', 'Valor'];
};
export const getActiveFinancial = () => {
  return [
    'Nome',
    'Categoria',
    'Selável',
    'Previdência Privada',
    'Liquidez',
    'Valor',
  ];
};
export const getPassivePatrimonial = () => {
  return [
    'Passivo',
    'Instituição',
    'PMT',
    ' Nº de Parcelas',
    'Taxa',
    'Valor à Vista',
    'Valor Total',
    'Descontos',
    'Quitados',
  ];
};
const allocationDefault = [
  { value: 'R. Fixa Pós', label: 'R. Fixa Pós' },
  { value: 'R. Fixa Pré', label: 'R. Fixa Pré' },
  { value: 'R. Fixa IPCA', label: 'R. Fixa IPCA' },
  { value: 'Multimercado', label: 'Multimercado' },
  { value: 'FIIs', label: 'FIIs' },
  { value: 'Ações BR', label: 'Ações BR' },
  { value: 'R.V. Exterior', label: 'R.V. Exterior' },
  { value: 'Alternativo', label: 'Alternativo' },
];

const goalsAllocationDefault = [
  ...allocationDefault,
  { value: 'ELP', label: 'ELP' },
];

const liquidityOptions = [
  { value: 'DO', label: 'DO' },
  { value: 'Até 30 dias', label: 'Até 30 dias' },
  { value: 'De 1 à 6 meses', label: 'De 1 à 6 meses' },
  { value: 'De 6 à 12 meses', label: 'De 6 à 12 meses' },
  { value: 'De 12 à 24 meses', label: 'De 12 à 24 meses' },
  { value: 'Acima de 24 meses', label: 'Acima de 24 meses' },
];
const passivePatrimonyOptions = [
  { value: 'car_financing', label: 'Financiamento Automóvel' },
  { value: 'real_estate_financing_sac', label: 'Financiamento Imóvel SAC' },
  {
    value: 'real_estate_financing_price',
    label: 'Financiamento Imóvel Price',
  },
  { value: 'car_consortium', label: 'Consórcio Automóvel' },
  { value: 'real_estate_consortium', label: 'Consórcio Imóvel' },
  { value: 'real_estate_balloon', label: 'Balão Imóvel' },
  { value: 'car_balloon', label: 'Balão Carro' },
  { value: 'other_patrimonial', label: 'Outros' },
];
const passiveFinanceOptions = [
  { value: 'personal_loan', label: 'Empréstimo pessoal' },
  { value: 'payroll_loan', label: 'Empréstimo consignado' },
  { value: 'friends_consortium', label: 'Consórcio entre amigos' },
  { value: 'other_financial', label: 'Outros' },
];

export {
  allocationDefault,
  liquidityOptions,
  goalsAllocationDefault,
  passivePatrimonyOptions,
  passiveFinanceOptions,
};
