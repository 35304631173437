import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const SimpleEditExtractItemModal = ({
  showModal = false,
  setShowModal = () => { },
  onConfirm = () => { },
  onCancel = () => { },
  onExclude = () => { },
  data,
  loading = false,
  categoriesListModalEdit = [],
  fetchCategoriesList = () => { },
  itemsListModalEdit = [],
  fetchItemsListByCategoryId = () => { },
  handleUpdate = () => { },
  currentPage,
}) => {
  useEffect(() => {
    if (!showModal) return;
    fetchCategoriesList(currentPage);
  }, [fetchCategoriesList, showModal, currentPage]);

  const [selectedCategory, setSelectedCategory] = useState({
    id: data.category_id || -1,
    label: 'Selecione...',
  });
  const [selectedItem, setSelectedItem] = useState({
    id: data.item_id || -1,
    label: 'Selecione...',
  });

  const [currentInstallment, setCurrentInstallment] = useState(
    data?.currentInstallment || 0
  );
  const [totalInstallments, setTotalInstallments] = useState(
    data?.totalInstallments || 0
  );
  const [name, setName] = useState(data.name || '');
  const [value, setValue] = useState(data?.value?.raw || data?.value || '');
  const [recurrent, setRecurrent] = useState(data.recurrent || false);
  const [start, setStart] = useState(data?.startDate || data?.start || '');
  const [end, setEnd] = useState(data?.endDate || data?.end || '');
  const [description, setDescription] = useState(data.description || '');

  useEffect(() => {
    setCurrentInstallment(data?.currentInstallment || 0);
    setTotalInstallments(data?.totalInstallments || 0);
    setName(data?.name || '');
    setValue(data?.value?.raw || data?.value || 0);
    setRecurrent(data?.recurrent || false);
    setStart(data?.startDate || data?.start || '');
    setEnd(data?.endDate || data?.end || '');
    setDescription(data?.description || '');
  }, [data, showModal]);

  useEffect(() => {
    if (selectedCategory.value !== -1) {
      fetchItemsListByCategoryId(selectedCategory.value);
    }
  }, [selectedCategory, fetchItemsListByCategoryId, data]);

  const dropdownOptionsCategories = useMemo(() => {
    if (categoriesListModalEdit?.length <= 0) {
      return [];
    }
    const defaultCategory = categoriesListModalEdit.find(
      category => data.category_id === category.id
    );

    if (defaultCategory) {
      setSelectedCategory({
        value: defaultCategory.id,
        label: defaultCategory.name,
      });
    }

    return categoriesListModalEdit.map(category => ({
      value: category.id,
      label: category.name,
    }));
  }, [categoriesListModalEdit, data.category_id]);

  const dropdownOptionsItems = useMemo(() => {
    if (itemsListModalEdit?.length <= 0) {
      return [];
    }

    const defaultItem = itemsListModalEdit.find(
      item => data.item_id === item.id
    );

    if (defaultItem) {
      setSelectedItem({
        value: defaultItem.id,
        label: defaultItem.name,
      });
    }

    return itemsListModalEdit.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [itemsListModalEdit, data.item_id]);

  if (loading) {
    return <LoadingModal showModal={showModal} setShowModal={setShowModal} />;
  }

  const modalProps = {
    showModal,
    setShowModal,
    selectedCategory,
    dropdownOptionsCategories,
    setSelectedCategory,
    selectedItem,
    dropdownOptionsItems,
    setSelectedItem,
    start,
    setStart,
    description,
    setDescription,
    recurrent,
    setRecurrent,
    end,
    setEnd,
    onConfirm: handleUpdate,
    onCancel,
    value: typeof value === 'number' ? value : 0,
    setValue,
    id: data.id,
    onExclude,
  };
  return data.hasOwnProperty('totalInstallments') ? (
    <EditInstallmentModal
      {...modalProps}
      currentInstallment={currentInstallment}
      setCurrentInstallment={setCurrentInstallment}
      totalInstallments={totalInstallments}
      setTotalInstallments={setTotalInstallments}
      name={name}
      setName={setName}
    />
  ) : (
    <EditEntryModal {...modalProps} />
  );
};

const LoadingModal = ({ showModal, setShowModal }) => (
  <Modal.Container
    showModal={showModal}
    setShowModal={setShowModal}
    className="extract-modal"
  >
    <Modal.TitleContainer>
      <GenericLoading className="simple-modal-delete-header" />
    </Modal.TitleContainer>
    <Modal.BodyContainer className="extract-modal">
      <GenericLoading className="simple-modal-edit-item-category" />
      <GenericLoading className="simple-modal-edit-item-category" />
      <GenericLoading className="simple-modal-edit-item-category" />
      <GenericLoading className="simple-modal-edit-item-category" />
      <Modal.GroupInputs show={true}>
        <GenericLoading className="simple-modal-edit-item-category-group-itens first" />
        <GenericLoading className="simple-modal-edit-item-category-group-itens second" />
      </Modal.GroupInputs>
    </Modal.BodyContainer>
    <Modal.SimpleFooter
      loading={true}
      loadingClassname="simple-modal-delete-footer"
      confirmButtonText="Editar"
    />
  </Modal.Container>
);

const EditInstallmentModal = ({
  showModal,
  setShowModal,
  selectedCategory,
  dropdownOptionsCategories,
  setSelectedCategory,
  selectedItem,
  dropdownOptionsItems,
  setSelectedItem,
  onConfirm,
  currentInstallment,
  setCurrentInstallment,
  totalInstallments,
  setTotalInstallments,
  value,
  setValue,
  name,
  setName,
  id,
  onExclude,
}) => {
  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="extract-modal"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Parcelado"
          onClose={() => setShowModal(false)}
          type="edit"
          className="extract-modal"
          theme="edit"
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer className="extract-modal">
        <Modal.Dropdown
          className="extract-modal"
          placeholderSelect={selectedCategory?.label}
          selectOptions={dropdownOptionsCategories || []}
          setDropdownValue={setSelectedCategory}
          label="Categoria"
        />
        <Modal.Dropdown
          className="extract-modal"
          placeholderSelect={selectedItem?.label}
          selectOptions={dropdownOptionsItems || []}
          setDropdownValue={setSelectedItem}
          label="Item"
        />
        <Modal.InputText
          className="extract-modal"
          placeholder={'O que é?'}
          label="Nome"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.InputText
          className="extract-modal"
          placeholder={'0'}
          label="Parcela Atual"
          getInputValue={setCurrentInstallment}
          defaultValue={currentInstallment}
        />
        <Modal.InputText
          className="extract-modal"
          placeholder={'0'}
          label="Total de Parcelas"
          getInputValue={setTotalInstallments}
          defaultValue={totalInstallments}
        />
        <Modal.InputCurrency
          className="extract-modal"
          label="Valor da Parcela"
          getInputValue={setValue}
          defaultValue={value}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={() =>
          onConfirm(id, 'Parcelado', setShowModal, {
            name,
            value,
            currentInstallment,
            totalInstallments,
            selectedItem,
            selectedCategory,
          })
        }
        onExclude={() => onExclude(id)}
        className="extract-modal"
        cancelButtonText="Cancelar"
        confirmButtonText="Editar"
      />
    </Modal.Container>
  );
};

const EditEntryModal = ({
  showModal,
  setShowModal,
  selectedCategory,
  dropdownOptionsCategories,
  setSelectedCategory,
  selectedItem,
  dropdownOptionsItems,
  setSelectedItem,
  start,
  setStart,
  description,
  setDescription,
  recurrent,
  setRecurrent,
  end,
  setEnd,
  onConfirm,
  onCancel,
  onExclude,
  id,
  value,
  setValue,
}) => (
  <Modal.Container
    showModal={showModal}
    setShowModal={setShowModal}
    className="extract-modal"
  >
    <Modal.TitleContainer>
      <Modal.SimpleTitleContent
        title="Editar item"
        onClose={() => setShowModal(false)}
        type="edit"
        className="extract-modal"
        theme="edit"
      />
    </Modal.TitleContainer>
    <Modal.BodyContainer className="extract-modal">
      <Modal.Dropdown
        className="extract-modal"
        placeholderSelect={selectedCategory?.label}
        selectOptions={dropdownOptionsCategories || []}
        setDropdownValue={setSelectedCategory}
        label="Categoria"
      />
      <Modal.Dropdown
        className="extract-modal"
        placeholderSelect={selectedItem?.label}
        selectOptions={dropdownOptionsItems || []}
        setDropdownValue={setSelectedItem}
        label="Item"
      />
      <Modal.DatePicker
        className="generic-dream-modal-datepicker full-width after"
        label="Data de início"
        getInputValue={setStart}
        defaultValue={start}
      />
      <Modal.InputText
        className="extract-modal"
        placeholder={''}
        label="Descrição"
        getInputValue={setDescription}
        defaultValue={description}
      />
      <Modal.InputCurrency
        className="generic-dream-modal"
        label="Valor"
        getInputValue={setValue}
        defaultValue={value}
      />

      <Modal.GroupInputs show={true}>
        <Modal.Switch
          label="Recorrente"
          className="extract-modal"
          getSwitchValue={setRecurrent}
          defaultValue={recurrent}
        />
        {recurrent && (
          <Modal.DatePicker
            className="generic-dream-modal-datepicker after top"
            label="Data de fim"
            getInputValue={setEnd}
            defaultValue={end}
          />
        )}
      </Modal.GroupInputs>
    </Modal.BodyContainer>
    <Modal.SimpleFooter
      onConfirm={() => {
        const resultData = {
          id,
          item_id: selectedItem.value,
          start,
          description,
          recurrent,
          end,
          value,
        };
        onConfirm(id, 'Entry', setShowModal, resultData);
      }}
      onExclude={() => onExclude(id)}
      className="extract-modal"
      cancelButtonText="Cancelar"
      confirmButtonText="Editar"
    />
  </Modal.Container>
);
