import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  width: min-content;
  height: min-content;
  align-items: flex-start;
`;
export const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
