import React from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { TableReport } from './Table';

export const ReportSpendingTable = ({
  currentPage,
  data,
  loading,
  handleEditClick,
}) => {
  const pages = {
    spendings: (
      <>
        {data.map((item, index) => (
          <TableReport
            data={item}
            type="spendings"
            title={item?.category_name || ''}
            key={Math.random()}
            handleEditClick={handleEditClick}
          />
        ))}
      </>
    ),
    receipt: (
      <>
        {data.map((item, index) => (
          <TableReport
            data={item}
            type="receipts"
            title={item?.category_name || ''}
            key={Math.random()}
            handleEditClick={handleEditClick}
          />
        ))}
      </>
    ),
    investment: (
      <>
        {data.map((item, index) => (
          <TableReport
            data={item}
            type="investments"
            title={item?.category_name || ''}
            key={Math.random()}
            canEdit={false}
          />
        ))}
      </>
    ),
  };

  if (loading) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          <LoadingTable key={index} />
        ))}
      </>
    );
  }

  return (
    <>

      {pages[currentPage] || <></>}
    </>
  );
};
