import React, { useEffect } from 'react';
import PatrimonyCard from 'components/organisms/PatrimonyCard';
import PotCard from 'components/organisms/PotCard';
import MainAssetsCard from 'components/organisms/MainAssetsCard';
import { Container } from './styles';
import SummaryCard from 'components/organisms/SummaryCard';
import DreamPanel from 'components/organisms/DreamPanel';
import RetirementManagement from 'components/organisms/RetirementManagement';
import { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { Page } from 'components/templates/Page';
import useDashboard from 'hooks/useDashboard';
import toast from 'react-hot-toast';

export default function Home() {
  const {
    patrimonies,
    mainAssets,
    recentSpendings,
    budgetCompositon,
    goals,
    loading,
  } = useDashboard();

  const { setLoading } = useContext(GeneralContext);
  useEffect(() => {
    if (loading) {
      toast.success('Seja bem vindo(a)!');
    }
    setLoading(loading);
  }, [loading, setLoading]);

  const toolTipTexts = {
    retirementManagement:
      'É o comparativo do plano projetado para atingir a independência financeira com o alcançado até este momento. Lembrando que essas projeções precisam ser revisadas periodicamente pela inflação.',
    liquidPatrimonyCard:
      'Resultado do total de ativos(bens, dinheiro investido, imóveis, etc) de uma pessoa ou família, descontados os passivos(dívidas, empréstimos, financiamentos,e outras obrigações).',
    financialPatrimonyCard:
      'É o dinheiro aplicado no mercado financeiro, como em títulos de renda fixa, ações, fundos de investimentos e previdência privada.',
    mainAssetsCard:
      'Ativos financeiros com maior percentual de aplicação do cliente no mercado.',
  };

  return (
    <Page.RootContainer>
      <Container className="first-column">
        <PatrimonyCard
          className={`home ${loading && 'home-loading'} home-number-card`}
          title="Patrimônio Líquido"
          number={patrimonies.liquid_patrimony}
          tooltipText={toolTipTexts.liquidPatrimonyCard}
          showToolTip
          url="/finances/patrimony/map"
        />
        <PatrimonyCard
          className={`home ${loading && 'home-loading'} home-number-card`}
          title="Patrimônio Financeiro"
          number={patrimonies.financial_active}
          showToolTip
          tooltipText={toolTipTexts.financialPatrimonyCard}
          url="/finances/patrimony/map"
        />
        <MainAssetsCard
          className={`home ${loading && 'home-loading'}`}
          title="Principais ativos"
          mainAssets={mainAssets}
          showToolTip
          tooltipText={toolTipTexts.mainAssetsCard}
          url="/finances/patrimony/planning"
        />
      </Container>
      <Container className="second-column">
        <PotCard
          className={`home ${loading && 'home-loading'}`}
          budgetEstimated={budgetCompositon.estimated}
          url="/management/detail"
          budgetActual={budgetCompositon.actual}
        />
        <SummaryCard
          className={`home ${loading && 'home-loading'}`}
          url="/finances/budget"
          title="Últimos lançamentos"
          spendingObjects={recentSpendings}
        />
      </Container>

      <Container className="third-column ">
        <DreamPanel
          className={`home ${loading && 'home-loading'}`}
          goals={goals}
          title="Painel de Sonhos"
          url="/goals/goalPanel"
        />
        <RetirementManagement
          url="/"
          title="Gestão de aposentadoria"
          className={`home ${loading && 'home-loading'}`}
          showToolTip
          toolTipText={toolTipTexts.retirementManagement}
        />
      </Container>
    </Page.RootContainer>
  );
}
