import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;

  @media (max-width: 1440px) {
    margin-top: 24px;
  }
`;

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const Wrapper = styled.div`
  display: flex;
`;
