import styled from 'styled-components';

export const StyledContainer = styled.div`
  &.detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 200px;
  }
`;
export const LegendValue = styled.div`
  &.detail {
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-size: 1.2rem;

    @media (max-width: 1023px) {
      font-size: 1.1rem;
    }

    &.first-column {
      color: ${({ theme }) => theme.blueRoyal};
    }

    &.second-column {
      color: ${({ theme }) => theme.blueDeep};
    }

    &.third-column {
      color: ${({ theme }) => theme.blueSky};
    }
    &.lilac {
      color: ${({ theme }) => theme.blueLilac};
    }
    &.blue-light {
      color: ${({ theme }) => theme.blueLight};
    }
    &.green-medium {
      color: ${({ theme }) => theme.greenMedium};
    }
    &.green-dark {
      color: ${({ theme }) => theme.greenDark};
    }
    &.red-light {
      color: ${({ theme }) => theme.redLight};
    }
    &.red-medium {
      color: ${({ theme }) => theme.redMedium};
    }
  }
`;
