import { useState, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const usePotDetail = () => {
  const { id: userId } = useSelector(state => state.user.profile);
  const { created_at: created } = useSelector(state => state.user.profile);

  const [date, setDate] = useState(new Date());
  const [budgetEstimated, setBudgetEstimated] = useState({});
  const [budgetActual, setBudgetActual] = useState({});
  const [rawBudgetEstimated, setRawBudgetEstimated] = useState({});
  const [rawBudgetActual, setRawBudgetActual] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async currentDate => {
      if (!userId) {
        toast.error('Usuário não encontrado.');
        return;
      }

      setLoading(true);
      try {
        const response = await api.get(`/budget/composition_detail/${userId}`, {
          params: { date: currentDate },
        });
        const { actual, estimated, actualRaw, estimatedRaw } = response.data;

        setBudgetActual(actual);
        setBudgetEstimated(estimated);
        setRawBudgetActual(actualRaw);
        setRawBudgetEstimated(estimatedRaw);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Ocorreu um erro ao buscar os dados.');
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  return {
    loading,
    fetchData,
    budgetActual,
    budgetEstimated,
    rawBudgetActual,
    rawBudgetEstimated,
    setDate,
    date,
    created,
  };
};

export default usePotDetail;
