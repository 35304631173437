import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { financialPatrimonialSchema } from 'utils/validator';
import toast from 'react-hot-toast';
import { liquidityOptions } from 'utils/patrimony';
import { getSpendingClassificationOptions } from 'utils/data';

export const AddFinancialAssets = ({
  showModal = false,
  setShowModal = () => {},
  activeProps,
  onConfirm = () => {},
  onCreate = () => {},
  theme,
}) => {
  const [name, setName] = useState(activeProps?.name);
  const [privatePension, setPrivatePension] = useState(
    activeProps?.private_pension
  );
  const [classification] = useState(activeProps?.classification);
  const [categorization, setCategorization] = useState(
    activeProps?.categorization
  );
  const [liquidity, setLiquidity] = useState(activeProps?.categorization);
  const [installmentValue, setInstallmentValue] = useState(
    activeProps?.value || 0
  );

  const classificationPension = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  const validateFields = () => {
    let errors = {};

    try {
      financialPatrimonialSchema.validateSync({
        name,
        classification,
        installmentValue,
        categorization,
        liquidity,
        privatePension,
      });
    } catch (error) {
      errors = { ...errors, [error.path]: error.message };
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        toast.error(error);
      });
      return;
    }

    const data = {
      name,
      classification: 'outros',
      value: installmentValue,
      type: 'financial-active',
      sealable: false,
      categorization,
      liquidity,
    };

    try {
      const response = await onCreate(data);
      onConfirm(response);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao criar o ativo financeiro.');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Novo Ativo Financeiro"
          onClose={() => setShowModal(false)}
          type="add"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome completo"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={getSpendingClassificationOptions()}
          getDropdownValue={setCategorization}
          label="Categoria"
          placeholderSelect={categorization}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={liquidityOptions}
          getDropdownValue={setLiquidity}
          label="Liquidez"
          placeholderSelect={liquidity}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationPension}
          getDropdownValue={setPrivatePension}
          label="Previdência Privada"
          placeholderSelect={privatePension}
        />
        <Modal.InputCurrency
          className="simple-modal after "
          label="Valor "
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
      </Modal.BodyContainer>
      <Modal.FooterWithOneButton
        onClick={handleSave}
        className={`generic-dream-modal`}
        text="Confirmar"
      />
    </Modal.Container>
  );
};
