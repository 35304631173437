import React from 'react';
import { EstimatedDetail } from 'components/molecules/EstimatedDetail';
import { RealizedDetail } from 'components/molecules/RealizedDetail';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import { getMonthsName } from 'utils/date';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const PotDetail = ({
  budgetActual,
  loading,
  budgetEstimated,
  rawBudgetEstimated,
  rawBudgetActual,
  selectedYear,
  selectedMonth,
  setSelectedYear,
  setSelectedMonth,
  yearOptions,
}) => {
  return loading ? (
    <Card.Container className="pot-detail">
      <GenericLoading className="detail-pot" />
    </Card.Container>
  ) : (
    <Card.Container className="pot-detail">
      <Table.TitleDropdown
        nameTable="Detalhamento do Pote"
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        yearOptions={yearOptions.map(year => ({ label: year, value: year }))}
        monthOptions={getMonthsName().map(month => ({
          label: month,
          value: month,
        }))}
        placeholderMonth={selectedMonth || 'Selecione um mês'}
        placeholderYear={selectedYear || 'Selecione um ano'}
      />
      <Card.Container className="card-detail">
        <EstimatedDetail
          budget={budgetEstimated}
          rawBudget={rawBudgetEstimated}
        />
        <RealizedDetail budget={budgetActual} rawBudget={rawBudgetActual} />
      </Card.Container>
    </Card.Container>
  );
};
