import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 300px;
  height: 65vh;
  min-height: 500px;
  margin-left: 5%;
  flex-shrink: 0;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 600px;
    padding-left: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
  }
`;

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const DivContainer = styled.div`
  &.home {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5%;
  }
`;
export const Line = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayShadow};
  width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 400px;
`;
