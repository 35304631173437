import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React, { useState, useEffect } from 'react';
import OptionOverlay from 'components/molecules/OptionOverlay';
import { CardContainer, Container, GroupIcons } from './styles';
import { getMonths } from 'utils/date';
import { generateClassName } from 'utils/text';
import Switch from 'components/atoms/Switch';

export const SpendingTable = ({
  classification,
  categoryName,
  totalPercentage,
  totalSpent,
  receiptList,
  categoryId,
  items = [],
  className,
  setShowEditItemModal,
  showDeleteModal,
  setShowEditCategoryModal,
  setShowDeleteModal,
  setShowSimpleAddModal,
  setSelectedCategory,
  setSelectedItem = () => { },
  getSelectedItems = () => { },
  updateCreditCardStatus,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const header = [
    'Nome',
    'Classificação',
    'Mês/Dia do mês',
    'Valor',
    'Gasto no cartão',
    'Valor Mensal',
  ];

  const itemKeys = ['name', 'classification', 'when', 'value', 'credit_card', 'monthly_value'];

  const months = getMonths();

  useEffect(() => {
    if (!selectedAll) {
      setSelectedItems([]);
    } else {
      const allIds = items.map(item => item?.id)
      setSelectedItems(allIds)
    }
  }, [selectedAll, items]);

  return (
    <>
      <CardContainer>
        <BudgetTableTitle
          setShowModal={setShowEditCategoryModal}
          nameTable={categoryName}
          total={totalSpent}
          percentage={totalPercentage}
          categoryId={categoryId}
          categoryClassification={classification}
          setSelectedCategory={setSelectedCategory}
        />
        <Container>
          <Table className="receipt-table">
            <thead>
              <Tr className="receipt-table-title spendings">
                <Th className="budget-table-title checkbox first-table-border">
                  <Checkbox
                    checked={selectedAll}
                    onClick={() => setSelectedAll(prev => !prev)}
                  />
                </Th>
                {header.map((item, index) => (
                  <Th className={generateClassName({
                    'budget-table-title spendings': true,
                    'first': index === 0
                  })} key={index}>
                    {item}
                  </Th>
                ))}
                <Th className="budget-table-title empty last-table-border" />
              </Tr>
            </thead>
            <tbody>
              {items?.map((item, index) => (
                <Tr className="receipt-table-body" key={index}>
                  <Td className="receipt-table-body checkbox">
                    <Checkbox
                      checked={selectedItems.includes(item?.id)}
                      onClick={() => {
                        if (selectedItems.includes(item?.id)) {
                          setSelectedItems(
                            selectedItems.filter(id => id !== item?.id)
                          );
                        } else {
                          setSelectedItems(prev => [...prev, item?.id]);
                        }
                      }}
                    />
                  </Td>
                  {itemKeys.map((itemKey, index) => {
                    const cellClassName = generateClassName({
                      'receipt-table-body': true,
                      'first': index === 0,
                    });

                    const renderCellValue = () => {
                      if (itemKey === 'when' && item['classification'] === 'Eventual Comprometido') {
                        return months[item[itemKey] - 1]?.value || '';
                      }
                      if (['value', 'monthly_value'].includes(itemKey)) {
                        return item[itemKey]?.formatted
                      }
                      if (itemKey === 'credit_card') {
                        return (
                          <Switch
                            checked={item[itemKey]}
                            onClick={() => updateCreditCardStatus(item?.id, !item[itemKey])}
                            getValue={() => { }}
                          />
                        );
                      }
                      return item[itemKey];
                    };

                    return (
                      <Td className={cellClassName} key={index}>
                        {renderCellValue()}
                      </Td>
                    );
                  })}
                  <Td className="receipt-table-body options">
                    <OptionOverlay
                      onClick={() => {
                        const selectedItem = {
                          recipeId: item?.id,
                          categoryId,
                          name: item?.name,
                          classification: item?.classification,
                          value: item?.value?.raw,
                          maturity: item?.when,
                          monthlyValue: item?.monthly_value,
                          recurrent: item?.recurrent ? true : false,
                        };
                        setSelectedItem(selectedItem);
                        setShowEditItemModal(prev => !prev);
                      }}
                      className={className}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Container>
        <GroupIcons>
          <AddIcon
            className="receipt-table"
            onClick={() => {
              setSelectedCategory(categoryId);
              setShowSimpleAddModal(prev => !prev);
            }}
          />
          <TrashIcon
            className="receipt-table"
            onClick={() => {
              getSelectedItems(selectedItems || []);
              setShowDeleteModal(prev => !prev);
            }}
          />
        </GroupIcons>
      </CardContainer>
    </>
  );
};
