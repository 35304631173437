import React, { useState, useEffect } from 'react';
import { StyledContainer, DivContainer, Line } from './styles';
import { BarGrafic } from '../../atoms/BarGrafic';
import { Span } from 'components/atoms/Span';
import { SubtitleBar } from 'components/atoms/SubtitleBar';
import { formatOutputMoneyNoDecimals } from 'utils/numbers';
import { LegendPotDetail } from '../LegendPotDetail';
import light from 'styles/themes/light';

export const RealizedDetail = ({ budget, rawBudget }) => {
  const [cutItems, setCutItems] = useState({});

  const itemsRealized = [
    { key: 'receipts', text: 'Recebimentos', className: 'first-column' },
    {
      key: 'flexibleEventual',
      text: 'Eventuais Flexíveis - LEF',
      className: 'blue-light',
    },
    {
      key: 'flexibleMonthly',
      text: 'Mensais Flexíveis',
      className: 'lilac',
    },
    {
      key: 'compromisedMonthly',
      text: 'Mensais Comprometidos',
      className: 'third-column',
    },
    {
      key: 'compromisedEventual',
      text: 'Eventuais Comprometidos - PEC',
      className: 'green-medium',
    },
    {
      key: 'investment',
      text: 'Investimentos',
      className: 'green-dark',
    },
    {
      key: 'installments',
      text: 'Compras Parceladas',
      className: 'red-light',
    },
    {
      key: 'debts',
      text: 'Dívidas',
      className: 'red-medium',
    },
  ];

  const calculateBalance = () => {
    const totalExpenses = itemsRealized
      .filter(item => item.key !== 'receipts')
      .map(item => (cutItems[item.key] ? 0 : rawBudget[item.key] || 0))
      .reduce((acc, value) => acc + value, 0);

    const receipt = cutItems['receipts'] ? 0 : rawBudget.receipts || 0;
    return receipt - totalExpenses;
  };

  useEffect(() => {
    setCutItems({});
  }, [budget]);

  const handleClick = item => {
    setCutItems(prevState => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const getValue = item => {
    const value = budget[item] || 0;
    return value;
  };

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Recebimentos',
        data: [cutItems.receipts ? 0 : rawBudget.receipts],
        backgroundColor: 'transparent',
        borderColor: light.blueRoyal,
        borderSkipped: 'none',
        borderWidth: 1.5,
        yAxisID: 'right',
      },
      {
        label: 'Dívidas',
        data: [cutItems.debts ? 0 : rawBudget.debts],
        backgroundColor: light.redMedium,
        borderWidth: 0,
      },
      {
        label: 'Compras Parceladas',
        data: [cutItems.installments ? 0 : rawBudget.installments],
        backgroundColor: light.redLight,
        borderWidth: 0,
      },
      {
        label: 'Investimentos',
        data: [cutItems.investment ? 0 : rawBudget.investment],
        backgroundColor: light.greenDark,
        borderWidth: 0,
      },
      {
        label: 'Eventuais Comprometidos - PEC',
        data: [
          cutItems.compromisedEventual ? 0 : rawBudget.compromisedEventual,
        ],
        backgroundColor: light.greenMedium,
        borderWidth: 0,
      },
      {
        label: 'Mensais Comprometidos',
        data: [cutItems.compromisedMonthly ? 0 : rawBudget.compromisedMonthly],
        backgroundColor: light.blueSky,
        borderWidth: 0,
      },
      {
        label: 'Mensais Flexíveis',
        data: [cutItems.flexibleMonthly ? 0 : rawBudget.flexibleMonthly],
        backgroundColor: light.blueLilac,
        borderWidth: 0,
      },
      {
        label: 'Eventuais Flexíveis - LEF',
        data: [cutItems.flexibleEventual ? 0 : rawBudget.flexibleEventual],
        backgroundColor: light.blueLight,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: { display: false },
    tooltips: {
      showAllTooltips: false,
      enabled: false,
      mode: 'index',
      bodySpacing: 12,
      bodyFontSize: 15,
    },
    plugins: { datalabels: { color: 'rgba(0,0,0,0)' } },
    scales: {
      xAxes: [{ display: false, stacked: true, barThickness: 100 }],
      yAxes: [
        {
          display: false,
          stacked: true,
          ticks: { beginAtZero: true, max: rawBudget.scale },
        },
        {
          id: 'right',
          display: false,
          gridLines: { display: false },
          ticks: { beginAtZero: true, max: rawBudget.scale },
        },
      ],
    },
  };

  return (
    <StyledContainer className="default">
      <Span className="pot-detail">Pote Realizado</Span>
      <DivContainer className="home">
        <LegendPotDetail
          budget={budget}
          cutItems={cutItems}
          getValue={getValue}
          handleClick={handleClick}
          items={itemsRealized}
        />
        <BarGrafic data={data} options={options} className="detail" />
      </DivContainer>
      <Line />
      <SubtitleBar
        className="detail"
        subtitleText="Saldo"
        subtitleValue={formatOutputMoneyNoDecimals(calculateBalance())}
      />
    </StyledContainer>
  );
};
