import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const H4 = styled.h4`
  &.apertura-regular {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily}, sans-serif;
  }

  &.blue-d2 {
    color: ${({ theme }) => theme.blueMidnight};
  }

  &.header {
    font-size: 2rem;
    line-height: 2;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    @media (max-width: 1650px) {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    @media (max-width: 1500px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    @media (max-width: 1280px) {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &.header-loading {
    width: 100%;
    height: 39px;
    color: transparent;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 50px;

    @media (max-width: 1920px) {
      height: 39px;
    }

    @media (max-width: 1650px) {
      height: 36px;
    }

    @media (max-width: 1500px) {
      height: 33px;
    }

    @media (max-width: 1280px) {
      height: 30px;
    }
  }
  &.map-header {
    font-family: ${({ theme }) => theme.fonts.PoppinsThinItalic.fontFamily}, sans-serif;
    font-size: 26px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    @media (max-width: 1440px) {
      font-size: 20px;
      line-height: 20px;
    }

    @media (max-width: 1060px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  &.progress-card {
    font-family: ${({ theme }) => theme.fonts.PoppinsBlack.fontFamily}, sans-serif;
    font-size: 24px;

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }
  &.value-card {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily}, sans-serif;
    font-size: 24px;
    margin-left: 10px;

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }
  &.gray-silver {
    color: ${({ theme }) => theme.graySilver};
  }

  &.font-spending {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily};
    font-size: 24px !important;

    @media (max-width: 1845px) {
      font-size: 18px !important;
    }
  }

  &.selected {
    color: ${({ theme }) => theme.white};
  }
  &.selected-patrimony {
    color: ${({ theme }) => theme.white};
    font-size: 24px;
    font-weight: 550;

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }
  &.passive {
    color: ${({ theme }) => theme.redMedium};
    font-size: 20px;

    &.blue {
      color: ${({ theme }) => theme.blueRoyal};
    }

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }
  &.receipts {
    color: ${({ theme }) => theme.blueNavy};
  }
  &.spending {
    color: ${({ theme }) => theme.blueSky};
  }

  &.investments {
    color: ${({ theme }) => theme.greenDark};
  }

  &.debts {
    color: ${({ theme }) => theme.redMedium};
  }

  &.receipts,
  &.spending,
  &.investments,
  &.debts,
  &.selected {
    font-size: 24px;

    @media (max-width: 1600px) {
      font-size: 18px;
    }
  }

  &.spending-card {
    font-size: 24px;

    @media (max-width: 1845px) {
      font-size: 18px;
    }
  }

  &.empty-suitability-questionnaire {
    font-size: 32px;
    font-weight: 450;
    line-height: 32px;
    text-align: center;
    color: ${({ theme }) => theme.blueMidnight};
    margin: 0;

    @media (max-width: 1023px) {
      font-size: 17.1px;
      line-height: 17.1px;
    }
  }

  &.filled-suitability-questionnaire {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
    margin-top: 51px;

    @media (max-width: 1023px) {
      font-size: 17.1px;
      line-height: 17.1px;
      margin-top: 27.2px;
    }
  }

  &.legend-table {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 24px;
    font-family: ${({ theme }) => theme.fonts.PoppinsBold.fontFamily}, sans-serif;

    @media (max-width: 1440px) {
      font-size: 20px;
    }

    &.spending-color {
      color: ${({ theme }) => theme.blueSky};
    }
  }

  &.investment-panel-title {
    display: block;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${({ theme }) => theme.blueMidnight};

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }

  &.immediate-liquidity-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${({ theme }) => theme.blueMidnight};

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }
  &.legend-bar {
    color: ${({ theme }) => theme.grayMedium};
    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily}, sans-serif;
    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }
  &.title-carousel {
    color: ${({ theme }) => theme.white};
    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily}, sans-serif;
    margin: 0;
    padding: 0;

    @media (max-width: 1550px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  &.value-carousel {
    color: ${({ theme }) => theme.white};
    font-size: 24px;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily}, sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 3px;
  }
`;
