import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;

    @media (max-width: 1400px) {
        width: 1224px;
    }
`;

export const ScrollContainer = styled.div`
    overflow-x: scroll;
    width: 100%;
`;