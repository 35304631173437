import React, { useState, useEffect } from 'react';
import DownIcon from 'components/atoms/icons/DownIcon';
import UpButton from 'components/molecules/UpButton';
import { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { ScrollToTopButtonContainer, ScrollToTopButton } from './styles';

const DirecionalButton = () => {
  const { loading } = useContext(GeneralContext);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeIcon, setActiveIcon] = useState(DownIcon);

  useEffect(() => {
    const updateScrollPosition = () => {
      const scrollHeight = window.pageYOffset;
      setScrollPosition(scrollHeight);

      if (scrollHeight > 0) {
        setActiveIcon(UpButton);
      } else {
        setActiveIcon(DownIcon);
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(updateScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <ScrollToTopButtonContainer className={` ${loading ? 'loading' : ''}`}>
      <ScrollToTopButton
        onClick={scrollPosition > 0 ? handleScrollToTop : handleScrollToBottom}
      >
        {activeIcon}
      </ScrollToTopButton>
    </ScrollToTopButtonContainer>
  );
};

export default DirecionalButton;
