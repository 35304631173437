import React from 'react';
import {
  Container,
  ContainerWrapper,
  TitleWrapper,
  GroupItens,
} from './styles';
import { Span } from 'components/atoms/Span';
import GoIcon from 'components/atoms/icons/GoIcon';
import TooltipIconWithOverlay from 'components/atoms/IconInformation';
import { useHistory } from 'react-router-dom';

export const InvestmentsTableTItle = ({
  nameTable,
  tooltipText = '',
  percentage,
  total,
  url,
}) => {
  const history = useHistory();
  const handleRedirect = newPath => history.push(`/${newPath}`);
  return (
    <Container>
      <ContainerWrapper>
        <Span className="category-title">{nameTable}</Span>
        <GoIcon className="budget-table" onClick={() => handleRedirect(url)} />
      </ContainerWrapper>
      <ContainerWrapper>
        <GroupItens>
          <TitleWrapper>
            <Span className="category-title">M.I.M.</Span>
            <TooltipIconWithOverlay
              className="home investments"
              tooltipText={tooltipText}
            />
          </TitleWrapper>
          <Span className="category-title">{total}</Span>
        </GroupItens>
        <GroupItens>
          <Span className={`category-title-two investments`}>Percentual</Span>
          <Span className={`category-title-two investments`}>{percentage}</Span>
        </GroupItens>
      </ContainerWrapper>
    </Container>
  );
};
