import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Span } from 'components/atoms/Span';
import { Button } from 'components/atoms/Button';
import SpendingsIcon from 'components/atoms/icons/SpendingsIcon';
import ReceiptIcon from 'components/atoms/icons/ReceiptIcon';
import InvestmentsIcon from 'components/atoms/icons/InvestmentsIcon';
import { Container, DivContainer, Wrapper } from './styles';
import { SaveReport } from '../SaveReport';
import { GenericLoading } from 'components/atoms/GenericLoading';

const icons = {
  spendings: SpendingsIcon,
  receipt: ReceiptIcon,
  investment: InvestmentsIcon,
};

const buttonClasses = {
  spendings: 'report-spending',
  receipt: 'report-receipt',
  investment: 'report-investment',
};

const buttonLabels = {
  spendings: 'Relatório de Gastos',
  receipt: 'Relatório de Recebimentos',
  investment: 'Relatório de Investimentos',
};

export default function SemiannualReportTitle({
  currentPage,
  setCurrentPage,
  loading,
}) {
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleSelectButton = (button) => {
    setCurrentPage(button);
  };

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const isButtonSelected = (button) => currentPage === button || hoveredButton === button;

  if (loading) {
    return (
      <Container>
        <DivContainer>
          <GenericLoading className="semiannual-report-title" />
          {Array.from({ length: 3 }, (_, index) => (
            <GenericLoading key={index} className="semiannual-report-go-icon" />
          ))}
        </DivContainer>
        <Wrapper>
          <GenericLoading className="semiannual-report-go-icon" />
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <DivContainer>
        <Span className="table-title color-blue">Relatório Semestral</Span>
        {Object.entries(icons).map(([buttonType, IconComponent]) => (
          <div key={buttonType} data-tip={buttonLabels[buttonType]}
            onMouseEnter={() => handleMouseEnter(buttonType)}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              className={`${buttonClasses[buttonType]} ${isButtonSelected(buttonType) ? 'selected' : ''}`}
              onClick={() => handleSelectButton(buttonType)}
            >
              <IconComponent
                className={`report ${isButtonSelected(buttonType) ? 'selected' : ''}`}
              />
            </Button>
          </div>
        ))}
        <ReactTooltip effect="solid" />
      </DivContainer>
      <Wrapper>
        <SaveReport />
      </Wrapper>
    </Container>
  );
}
