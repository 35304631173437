import React, { useCallback, useEffect, useState } from 'react';
import { SimpleTableWithCheckboxAtTheEnd } from 'components/templates/Table/SimpleTableWithCheckboxAtTheEnd';
import { InstallmentPurchaseTable } from 'components/templates/Table/InstallmentPurchaseTable';
import { EditDebtItemModal } from 'components/molecules/BudgetModal/EditDebtItemModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import useBudgetManagement from 'hooks/useBudgetManagement';
import toast from 'react-hot-toast';

export const DebtMappingPage = ({ data, reloadPageData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [spendingCategories, setSpendingCategories] = useState([]);

  const {
    deleteInstallments,
    deleteManyInstallments,
    getCategoriesWithItems,
    createInstallment,
    updateInstallment,
  } = useBudgetManagement();

  const handleDelete = async () => {
    const toastLoading = toast.loading('Deletando...');
    const delectedItems = await deleteManyInstallments(selectedItems);

    if (!delectedItems.success) {
      toast.error(delectedItems.message, {
        id: toastLoading,
      });
    }

    await reloadPageData();
    setShowDeleteModal(false);
    setSelectedItems([]);

    toast.success('Itens deletados com sucesso!', {
      id: toastLoading,
    });
  };

  const deleteUniqueItem = async () => {
    const toastLoading = toast.loading('Deletando item...');
    const deletedItem = await deleteInstallments(selectedItem.id);

    if (!deletedItem.success) {
      toast.error(deletedItem.message, {
        id: toastLoading,
      });
    }
    await reloadPageData();
    setShowModal(false);
    setSelectedItem(null);

    toast.success('Item deletado com sucesso!', {
      id: toastLoading,
    });
  };

  const addNewItem = async data => {
    const toastLoading = toast.loading('Criando item...');
    const createdItem = await createInstallment(data);

    if (!createdItem.success) {
      toast.error(createdItem.message, {
        id: toastLoading,
      });
      return;
    }

    await reloadPageData();
    setShowAddModal(false);
    setSelectedItem(null);

    toast.success('Item criado com sucesso!', {
      id: toastLoading,
    });
  };

  const editItem = async data => {
    const toastLoading = toast.loading('Atualizando item...');
    const updatedItem = await updateInstallment(data);

    if (!updatedItem.success) {
      toast.error(updatedItem.message, {
        id: toastLoading,
      });
      return;
    }

    await reloadPageData();
    setShowModal(false);
    setSelectedItem(null);

    toast.success('Item atualizado com sucesso!', {
      id: toastLoading,
    });
  };

  const getCategories = useCallback(async () => {
    const categories = await getCategoriesWithItems({
      type: 'spendings',
      items: true,
    });
    setSpendingCategories(categories);
  }, [getCategoriesWithItems]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <>
      {showModal && (
        <EditDebtItemModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          title="Editar item"
          icon="edit"
          onCancel={editItem}
          cancelButtonText="Concluído"
          confirmButtonText="Excluir item"
          onConfirm={deleteUniqueItem}
          theme="debts"
          allCategories={spendingCategories}
        />
      )}
      {showAddModal && (
        <EditDebtItemModal
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          title="Adicionar item"
          icon="add"
          onCancel={addNewItem}
          cancelButtonText="Concluído"
          confirmButtonText="Excluir item"
          theme="debts"
          allCategories={spendingCategories}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title={selectedItems.length > 1 ? 'Excluir itens' : 'Excluir item'}
          text={selectedItems.length > 1 ? 'Excluir itens?' : 'Excluir item?'}
          highlightedText="Esta ação é permanente e não poderá ser desfeita"
          onConfirm={handleDelete}
          cancelButtonText="Cancelar"
          confirmButtonText={
            selectedItems.length > 1 ? 'Excluir itens' : 'Excluir item'
          }
          theme="debts"
        />
      )}
      <InstallmentPurchaseTable
        body={data.installments.data}
        setShowModal={setShowModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        nameTable="Compras Parceladas"
        totalCategoryPercentage={data.installments.percentage}
        totalCategoryValue={data.installments.total}
        showEditIconOnTitle={false}
        typeCard="debts"
        className="color-debts"
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        setSelectedItem={setSelectedItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <SimpleTableWithCheckboxAtTheEnd
        body={data.patrimonial_passive.data}
        nameTable="Passivos Patrimoniais"
        totalCategoryPercentage={data.patrimonial_passive.percentage}
        totalCategoryValue={data.patrimonial_passive.total}
        showEditIconOnTitle={false}
        typeCard="debts"
        url="finances/patrimony/map"
      />
      <SimpleTableWithCheckboxAtTheEnd
        body={data.financial_passive.data}
        nameTable="Passivos Financeiros"
        totalCategoryPercentage={data.financial_passive.percentage}
        totalCategoryValue={data.financial_passive.total}
        showEditIconOnTitle={false}
        typeCard="debts"
        url="finances/patrimony/map"
      />
    </>
  );
};
