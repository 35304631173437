import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { activePatrimonialSchema } from 'utils/validator';
import toast from 'react-hot-toast';

export const EditMaterialAssets = ({
  showModal = false,
  setShowModal = () => { },
  onUpdate = () => { },
  onConfirm = () => { },
  theme,
  activeProps,
}) => {
  const [name, setName] = useState(activeProps?.name);
  const [classification, setClassification] = useState(
    activeProps?.classification
  );
  const [installmentValue, setInstallmentValue] = useState(activeProps?.value?.raw || 0);

  const classificationOptions = [
    { value: 'Moradia', label: 'Moradia' },
    { value: 'Imóvel de Investimento', label: 'Imóvel de Investimento' },
    { value: 'Transporte', label: 'Transporte' },
    { value: ' Outros', label: 'Outros' },
  ];
  const validateFields = () => {
    let errors = {};

    try {
      activePatrimonialSchema.validateSync({
        name,
        classification,
        installmentValue,
      });
    } catch (error) {
      errors = { ...errors, [error.path]: error.message };
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        toast.error(error);
      });
      return;
    }

    const data = {
      name,
      classification,
      value: installmentValue,
      sealable: false,
    };

    try {
      const response = await onUpdate(activeProps?.id, data);
      onConfirm(response);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao criar o ativo material.');
    } finally {
      setShowModal(false);
    }
  };
  const cancel = () => {
    setShowModal(false);
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar ativo"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome completo"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationOptions}
          getDropdownValue={setClassification}
          label="Tipo de Ativo"
          placeholderSelect={classification}
        />
        <Modal.InputCurrency
          className="simple-modal after "
          label="Valor "
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={handleSave}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Concluído"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
