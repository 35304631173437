import React, { useMemo, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import NavegationButton from '../NavegationButtons';
import { getNewRealease } from 'development/home';
import { toast } from 'react-toastify';
import {
  receiptSchema,
  debtsSchema,
  spendingSchema,
  investmentSchema,
} from 'utils/validator';

export const AddRealease = ({
  showModal = false,
  setShowModal = () => { },
  title = 'Novo Lançamento',
  icon = 'add',
  onConfirm = () => { },
  theme = 'edit',
}) => {
  const [currentPage, setCurrentPage] = useState('receipt');

  const [formData, setFormData] = useState({
    name: '',
    classification: '',
    value: '',
    inicialDate: '',
    finalDate: '',
    description: '',
    installment: '',
    recurring: false,
  });

  const options = useMemo(() => getNewRealease(), []);

  const handleChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const additionalSettings = {
    receipt: (
      <>
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={options.receiptClassification}
          getDropdownValue={value => handleChange('classification', value)}
          label="Classificação"
          placeholderSelect={formData.classification}
        />
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={options.receiptName}
          getDropdownValue={value => handleChange('name', value)}
          label="Nome do item"
          placeholderSelect={formData.name}
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker full-width after"
          label="Data de Início"
          getInputValue={value => handleChange('inicialDate', value)}
          defaultValue={formData.inicialDate}
        />
        <Modal.InputTextAdd
          className="generic-dream-modal after"
          label="Descrição"
          getInputValue={value => handleChange('description', value)}
          defaultValue={formData.description}
        />
        <Modal.GroupInputs show={true}>
          <Modal.Switch
            label="Recorrente"
            className="generic-dream-modal after"
            getSwitchValue={value => handleChange('recurring', value)}
            defaultValue={formData.recurring}
          />
          <Modal.InputCurrency
            label="Valor"
            getInputValue={value => handleChange('value', value)}
            className="generic-dream-modal first half second-section"
            defaultValue={formData.value}
          />
        </Modal.GroupInputs>
        {formData.recurring && (
          <Modal.DatePicker
            className="generic-dream-modal-datepicker after right"
            label="Data do Término"
            getInputValue={value => handleChange('finalDate', value)}
            defaultValue={formData.finalDate}
          />
        )}
      </>
    ),
    spendings: (
      <>
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={options.spendingClassification}
          getDropdownValue={value => handleChange('classification', value)}
          label="Classificação"
          placeholderSelect={formData.classification}
        />
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={options.spendingName}
          getDropdownValue={value => handleChange('name', value)}
          label="Nome do item"
          placeholderSelect={formData.name}
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker full-width after"
          label="Data de Início"
          getInputValue={value => handleChange('inicialDate', value)}
          defaultValue={formData.inicialDate}
        />
        <Modal.InputTextAdd
          className="generic-dream-modal after"
          label="Descrição"
          getInputValue={value => handleChange('description', value)}
          defaultValue={formData.description}
        />
        <Modal.GroupInputs show={true}>
          <Modal.Switch
            label="Recorrente"
            className="generic-dream-modal after"
            getSwitchValue={value => handleChange('recurring', value)}
            defaultValue={formData.recurring}
          />
          <Modal.InputCurrency
            label="Valor"
            getInputValue={value => handleChange('value', value)}
            className="generic-dream-modal first half second-section"
            defaultValue={formData.value}
          />
        </Modal.GroupInputs>
        {formData.recurring && (
          <Modal.DatePicker
            className="generic-dream-modal-datepicker after right"
            label="Data do Término"
            getInputValue={value => handleChange('finalDate', value)}
            defaultValue={formData.finalDate}
          />
        )}
      </>
    ),
    debts: (
      <>
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={options.debtsClassification}
          getDropdownValue={value => handleChange('classification', value)}
          label="Classificação"
          placeholderSelect={formData.classification}
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker full-width after"
          label="Data de Início"
          getInputValue={value => handleChange('inicialDate', value)}
          defaultValue={formData.inicialDate}
        />
        <Modal.InputTextAdd
          className="generic-dream-modal after"
          label="Descrição"
          getInputValue={value => handleChange('description', value)}
          defaultValue={formData.description}
        />
        <Modal.GroupInputs show={true}>
          <Modal.InputCurrency
            label="Valor"
            getInputValue={value => handleChange('value', value)}
            className="generic-dream-modal first half first-section"
            defaultValue={formData.value}
          />
          <Modal.InputTextAdd
            className="generic-dream-modal first half second-section"
            label="Parcelamento"
            getInputValue={value => handleChange('installment', value)}
            defaultValue={formData.installment}
          />
        </Modal.GroupInputs>
      </>
    ),
    investment: (
      <>
        <Modal.InputCurrency
          label="Valor"
          getInputValue={value => handleChange('value', value)}
          className="generic-dream-modal after"
          defaultValue={formData.value}
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker full-width after"
          label="Data"
          getInputValue={value => handleChange('inicialDate', value)}
          defaultValue={formData.inicialDate}
        />
      </>
    ),
  };

  const additionalSettingsContent = useMemo(() => {
    return additionalSettings[currentPage] || <></>;
  }, [currentPage, additionalSettings]);

  const validateForm = async () => {
    let schema;

    switch (currentPage) {
      case 'receipt':
        schema = receiptSchema;
        break;
      case 'spendings':
        schema = spendingSchema;
        break;
      case 'debts':
        schema = debtsSchema;
        break;
      case 'investment':
        schema = investmentSchema;
        break;
      default:
        schema = null;
    }

    if (!schema) {
      toast.error('Esquema de validação não encontrado.');
      return false;
    }
    try {
      await schema.validate(formData, { abortEarly: false });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          toast.error(error.message);
        });
      }
      return false;
    }
  };

  const confirm = async () => {
    if (await validateForm()) {
      onConfirm(formData);
      setShowModal(false);
    }
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="generic-dream-modal edit gallery-modal"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <NavegationButton
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Modal.BodyContainer className="realease">
        {additionalSettingsContent}
      </Modal.BodyContainer>
      <Modal.FooterWithOneButton
        text="Salvar transação"
        className="generic-dream-modal"
        onClick={confirm}
      />
    </Modal.Container>
  );
};
