import React, { useEffect } from 'react';
import DirecionalButton from 'components/organisms/Direcional';
import { ReportSpendingTable } from 'components/organisms/ReportSpendingTable';
import SemiannualReportTitle from 'components/molecules/SemiannualReportTitle';
import { CarouselReport } from 'components/molecules/CarouselReport';
import {
  PageContainer,
} from './styles';
import useReport from 'hooks/useReport';
import toast from 'react-hot-toast';
import { validateReportItem } from 'utils/validator';
import { EditSpendingItemModal } from 'components/molecules/BudgetModal/EditSpendingItemModal';

export default function Report() {
  const {
    bodyData,
    spendingByMonth,
    currentPage,
    setCurrentPage,
    updateMonthData,
    loading,
    fetchItemValue,
    selectedItem,
    updateItem,
    fetchValues,
    deleteItem,
    showModal,
    setShowModal,
  } = useReport();

  const [loadingModal, setLoadingModal] = React.useState(false);

  const handleEditClick = React.useCallback(async (item) => {
    try {
      setLoadingModal(true);
      setShowModal(true);
      await fetchItemValue(item?.item_id);
    } catch (error) {
      toast.error('Nao foi possivel carregar o item da modal');
    } finally {
      setLoadingModal(false);
    }
  }, [fetchItemValue, setShowModal]);

  const editItem = React.useCallback(async (updatedItem) => {
    try {
      setLoadingModal(true);
      const validated = await validateReportItem(updatedItem);

      if (!validated.success) {
        toast.error(validated.error);
        return;
      }

      await updateItem(selectedItem?.id, validated.data);
      await fetchValues();
      toast.success('Item atualizado com sucesso!');
      setShowModal(false);
    } catch (error) {
      console.error(error);
      toast.error('Não foi possível atualizar o item');
    } finally {
      setLoadingModal(false);
    }
  }, [selectedItem, updateItem, fetchValues, setShowModal]);

  const handleDeleteItem = React.useCallback(async () => {
    try {
      setLoadingModal(true);
      await deleteItem(selectedItem?.id);
      await fetchValues();
      setShowModal(false);
      toast.success('Item deletado com sucesso!');
    } catch (error) {
      toast.error('Não foi 가능 deletar o item');
    } finally {
      setLoadingModal(false);
    }
  }, [deleteItem, fetchValues, setShowModal, selectedItem]);

  useEffect(() => {
    if (!loading && bodyData.length === 0) {
      toast.error('Não foi possivel carregar os dados da página!');
    }
  }, [loading, bodyData]);

  return (
    <PageContainer>
      {showModal && (
        <EditSpendingItemModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          title="Editar recebimento"
          onCancel={editItem}
          onConfirm={handleDeleteItem}
          icon="edit"
          theme="edit"
          loading={loadingModal}
        />
      )}
      <SemiannualReportTitle
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        updateMonthData={updateMonthData}
        loading={loading}
      />
      <CarouselReport
        currentPage={currentPage}
        months={spendingByMonth}
        updateMonthData={updateMonthData}
        loading={loading}
      />
      <ReportSpendingTable
        currentPage={currentPage}
        data={bodyData}
        loading={loading}
        handleEditClick={handleEditClick}
      />
      <DirecionalButton />
    </PageContainer>
  )
}
