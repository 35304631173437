import { H4 } from 'components/atoms/Headings/H4';
import SetLeftIcon from 'components/atoms/icons/SetLeftIcon';
import SetRightIcon from 'components/atoms/icons/SetRightIcon';
import { Card } from 'components/templates/Card';
import React, { useRef } from 'react';
import { CardContainer, CarouselContainer, CarouselContent } from './styles';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const CarouselReport = ({
  currentPage,
  months,
  updateMonthData,
  loading,
}) => {
  const carouselRef = useRef(null);

  const handlePrev = () => {
    updateMonthData(-1);
  };

  const handleNext = () => {
    updateMonthData(1);
  };

  if (loading) {
    return (
      <CarouselContainer>
        <GenericLoading className="semiannual-report-months-arrows first" />
        <CarouselContent ref={carouselRef} className="carousel-content">
          {Array.from({ length: 6 }, (_, index) => (
            <GenericLoading key={index} className="semiannual-report-months" />
          ))}
        </CarouselContent>
        <GenericLoading className="semiannual-report-months-arrows last" />
      </CarouselContainer>
    );
  }

  return (
    <CarouselContainer>
      <SetLeftIcon
        onClick={handlePrev}
        className='blue'
      >
        &#8249;
      </SetLeftIcon>
      <CarouselContent ref={carouselRef} className="carousel-content">
        {months.map((month, i) => (
          <CardContainer key={i}>
            <Card.Container className={`carousel-report ${currentPage}`}>
              <Card.Content className="carousel">
                <H4 className="title-carousel">{month.name}</H4>
                <H4 className="value-carousel">{month.value}</H4>
              </Card.Content>
            </Card.Container>
          </CardContainer>
        ))}
      </CarouselContent>

      <SetRightIcon onClick={handleNext}>&#8250;</SetRightIcon>
    </CarouselContainer>
  );
};
