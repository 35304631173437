import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
`;

export const CarouselContent = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`;

export const CardContainer = styled.div`
  display: inline-flex;
  width: 16%;
  min-width: 181px;
`;
