import React from 'react';
import { ModalFooter } from './styles';
import { Button } from 'components/atoms/Button';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const SimpleModalFooter = ({
  className,
  onCancel,
  onConfirm,
  onExclude,
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Concluído',
  loading = false,
  loadingClassname = 'loading',
  buttonOrder = ['confirm', 'exclude', 'cancel'],
}) => {
  if (loading) {
    return (
      <ModalFooter>
        <GenericLoading className={loadingClassname + ' first'} />
        <GenericLoading className={loadingClassname + ' second'} />
      </ModalFooter>
    );
  }

  const renderButton = (buttonType) => {
    switch (buttonType) {
      case 'confirm':
        return (
          <Button className={`${className} cancel`} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        );
      case 'exclude':
        return (
          onExclude && (
            <Button className={`${className} confirm`} onClick={onExclude}>
              Excluir
            </Button>
          )
        );
      case 'cancel':
        return (
          onCancel && (
            <Button className={`${className} confirm`} onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )
        );
      default:
        return null;
    }
  };

  return (
    <ModalFooter>
      {buttonOrder.map((buttonType) => renderButton(buttonType))}
    </ModalFooter>
  );
};
