import styled from 'styled-components';

export const RootContainer = styled.div`
  position: relative;
  overflow-y: visible;

  &.full {
    width: 95vw;
    padding-left: 5%;
    padding-right: 32px;
  }
  &.detail {
    padding-left: 5%;
    padding-right: 32px;
    overflow: auto;
    width: 95vw;
  }
`;
