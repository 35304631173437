import React from 'react';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import { generateClassName } from 'utils/text';
import { Wrapper, ScrollContainer } from './styles';

export const TableReport = ({
  data,
  type,
  title,
  handleEditClick,
  canEdit = true
}) => {
  const headers = ['Item', 'Estimado', '', ...data?.total_monthly_entries?.monthly_data.map(item => item.name), '', 'Média', 'Soma'];

  return (
    <Card.Container className="spending-table">
      <Table.TitleSet nameTable={title} className="patrimony none" />
      <ScrollContainer>
        <Wrapper>
          <Card.Container className="table-recom">
            <Table.Container className='report-table'>
              <Table.TableBasic className='report-table'>
                <Table.Thead>
                  <Table.Tr className={`receipt-table-title ${type}`}>
                    {canEdit && <Table.Th className="empty first-table-border" />}
                    {headers.map((headerItem, index) => {
                      if ([2, 9].includes(index)) {
                        return (
                          <Table.Th className='fake-empty' key={index} />
                        )
                      }

                      return (
                        <Table.Th
                          key={index}
                          className={generateClassName({
                            'budget-table-title': true,
                            'first-table-border': [canEdit ? undefined : 0, 3, 10].includes(index),
                            'last-table-border': [1, 8, 11].includes(index),
                            'left': index === 0,
                            'first': index === 0,
                            'totals': index === headers.length - 1 || index === headers.length - 2,
                          })}
                        >
                          {headerItem}
                        </Table.Th>
                      )
                    })}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {data.items?.map((column, rowIndex) => (
                    <Table.Tr className="receipt-table-body" key={rowIndex}>
                      {canEdit && (
                        <Table.Td className="receipt-table-body pencil">
                          <PencilIcon className="category-title" onClick={() => handleEditClick(column)} />
                        </Table.Td>
                      )}
                      <Table.Td className="receipt-table-body first">{column?.item_name}</Table.Td>
                      <Table.Td className="receipt-table-body">{column?.estimated}</Table.Td>
                      <Table.Td className='fake-empty' />
                      {column?.item_spendings_by_month?.monthly_data.map((item, index) => (
                        <Table.Td key={index} className="receipt-table-body blue">{item?.value}</Table.Td>
                      ))}
                      <Table.Td className='fake-empty' />
                      <Table.Td className="receipt-table-body">{column?.item_spendings_by_month?.average}</Table.Td>
                      <Table.Td className="receipt-table-body">{column?.item_spendings_by_month?.total}</Table.Td>
                    </Table.Tr>
                  ))}
                  <Table.Tr className="receipt-table-body no-border">
                    {canEdit && <Table.Td className='empty' />}
                    <Table.Td className="report-table-total left">Total</Table.Td>
                    <Table.Td className="report-table-total">{data?.total_monthly_entries?.estimated}</Table.Td>
                    <Table.Td className='fake-empty' />
                    {data?.total_monthly_entries?.monthly_data.map((column, index) => (
                      <Table.Td key={index} className="report-table-total">{column?.value || 'R$0'}</Table.Td>
                    ))}
                    <Table.Td className='fake-empty' />
                    <Table.Td className="report-table-total totals">{data?.category_total_average || 'R$0'}</Table.Td>
                    <Table.Td className="report-table-total totals">{data?.category_total_sum || 'R$0'}</Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table.TableBasic>
            </Table.Container>
          </Card.Container>
        </Wrapper>
      </ScrollContainer>
    </Card.Container>
  );
};
