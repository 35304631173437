import React from 'react';
import { DivContainer, StyledContainer } from './styles';
import SaveIcon from 'components/atoms/icons/SaveIcon';

export const SaveReport = ({ onClick, className }) => {

  return (
    <StyledContainer onClick={onClick}>
      <DivContainer
        data-tip="Baixar informações para o Excel"
      >
        <SaveIcon />
      </DivContainer>
    </StyledContainer>
  );
};
