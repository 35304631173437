import { ReceiptTable } from 'components/templates/Table/ReceiptTable';
import React, { useState } from 'react';
import { ButtonCategory } from 'components/molecules/ButtonCategory';
import { EditRevenueItemModal } from '../../molecules/BudgetModal/EditRevenueItemModal';
import { GenericEditCategoryTableModal } from 'components/molecules/BudgetModal/GenericEditCategoryTableModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import { SimpleAddModal } from 'components/molecules/BudgetModal/SimpleAddModal';
import { ButtonContainer } from './styles';
import useBudgetManagement from 'hooks/useBudgetManagement';
import toast from 'react-hot-toast';
import { getItemsClassificationOptions, getReceiptsClassificationOptions } from 'utils/data';
import { updateItemSchema } from 'utils/validator';

export const RecipeMapPage = ({ data, reloadPageData }) => {
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSimpleAddModal, setShowSimpleAddModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const classificationOptions = getReceiptsClassificationOptions()
  const itemsClassificationOptions = getItemsClassificationOptions()

  const {
    createItem,
    updateItem,
    deleteItem,
    createCategory,
    deleteCategory,
    updateCategory,
    deleteManyItems
  } = useBudgetManagement();

  const addItem = async (data) => {
    const toastLoading = toast.loading('Criando item...');
    const createdItem = await createItem(data);

    if (!createdItem.success) {
      toast.error(createdItem.message, {
        id: toastLoading
      });
      return
    }


    await reloadPageData();
    setShowAddItemModal(false);
    setSelectedItem(null);

    toast.success("Item criado com sucesso!", {
      id: toastLoading
    });

  }

  const editCategory = async (data) => {
    const toastLoading = toast.loading('Atualizando categoria...');

    const uptadedCategory = await updateCategory({
      name: data.name,
      id: data.id,
      classification: data.classification
    });

    if (!uptadedCategory.success) {
      toast.error(uptadedCategory.message, {
        id: toastLoading
      });
      return
    }

    await reloadPageData();
    setShowEditCategoryModal(false);
    setSelectedCategory(null);

    toast.success("Categoria atualizada com sucesso!", {
      id: toastLoading
    });
  }

  const editItem = async (data) => {
    const formatedData = await updateItemSchema({ ...data, when: data?.maturity });

    if (!formatedData.success) {
      toast.error(formatedData.message);
      return null
    }

    const toastLoading = toast.loading('Atualizando item...');
    const verifyRecurrence = formatedData?.data?.recurrent !== null && formatedData?.data?.recurrent !== undefined;
    const updatedItem = await updateItem(formatedData?.data, verifyRecurrence);

    if (!updatedItem.success) {
      toast.error(updatedItem.message, {
        id: toastLoading
      });
      return
    }

    await reloadPageData();
    setShowEditItemModal(false);
    setSelectedItem(null);

    toast.success("Item atualizado com sucesso!", {
      id: toastLoading
    });
  }

  const deleteOneItem = async (id) => {
    const toastLoading = toast.loading('Deletando item...');
    const deletedItem = await deleteItem(id);

    if (!deletedItem.success) {
      toast.error("Não foi possível deletar o item!", {
        id: toastLoading
      });
      return
    }

    await reloadPageData();
    setShowEditItemModal(false);
    setSelectedItem(null);

    toast.success("Item deletado com sucesso!", {
      id: toastLoading
    })
  }

  const handleDeleteItems = async () => {
    if (selectedItems.length === 0) {
      toast.error('Selecione pelo menos um item para deletar!');
      setShowDeleteModal(true);
      return
    }

    const toastLoading = toast.loading('Deletando itens...');
    const deletedItems = await deleteManyItems(selectedItems);

    if (!deletedItems.success) {
      toast.error("Não foi possível deletar os itens!", {
        id: toastLoading
      });
    }

    await reloadPageData();
    setShowDeleteModal(false);
    setSelectedItems([]);

    toast.success("Itens deletados com sucesso!", {
      id: toastLoading
    })
  }

  const addNewCategory = async (data) => {
    const toastLoading = toast.loading('Criando categoria...');

    const createdCategory = await createCategory({
      name: data.name,
      classification: 'Recebimento',
      type: 'receipts',
    });

    if (!createdCategory.success) {
      toast.error(createdCategory.message, {
        id: toastLoading
      });
      return
    }

    await reloadPageData();
    setShowSimpleAddModal(false);
    setSelectedCategory(null);

    toast.success("Categoria criada com sucesso!", {
      id: toastLoading
    });
  }

  const handleDeleteCategory = async () => {
    const toastLoading = toast.loading('Deletando categoria...');
    const deletedCategory = await deleteCategory(selectedCategory.categoryId);

    if (!deletedCategory.success) {
      toast.error(deletedCategory.message, {
        id: toastLoading
      });
      return
    }

    await reloadPageData();
    setShowEditCategoryModal(false);
    setSelectedCategory(null);

    toast.success("Categoria deletada com sucesso!", {
      id: toastLoading
    });
  }

  return (
    <>
      {showEditItemModal && (
        <EditRevenueItemModal
          showModal={showEditItemModal}
          setShowModal={setShowEditItemModal}
          selectedItem={selectedItem}
          onCancel={editItem}
          onConfirm={deleteOneItem}
          theme='receipt'
          classificationOptions={itemsClassificationOptions}
        />
      )}
      {showAddItemModal && (
        <EditRevenueItemModal
          showModal={showAddItemModal}
          setShowModal={setShowAddItemModal}
          title='Adicionar item'
          icon='add'
          onCancel={addItem}
          theme='receipt'
          classificationOptions={itemsClassificationOptions}
          selectedCategory={selectedCategory}
        />
      )}
      {showEditCategoryModal && (
        <GenericEditCategoryTableModal
          showModal={showEditCategoryModal}
          setShowModal={setShowEditCategoryModal}
          selectedItem={selectedCategory}
          onCancel={editCategory}
          onConfirm={handleDeleteCategory}
          theme='receipt'
          classificationOptions={classificationOptions}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          text={selectedItems.length > 1 ? 'Excluir itens?' : 'Excluir item?'}
          highlightedText='Esta ação é permanente e não poderá ser desfeita'
          onConfirm={handleDeleteItems}
          cancelButtonText='Cancelar'
          confirmButtonText={selectedItems.length > 1 ? 'Excluir itens?' : 'Excluir item?'}
          theme='receipt'
        />
      )}
      {showSimpleAddModal && (
        <SimpleAddModal
          showModal={showSimpleAddModal}
          setShowModal={setShowSimpleAddModal}
          classificationOptions={classificationOptions}
          cancelButtonText='Concluído'
          confirmButtonText='Adicionar categoria'
          dropdownLabel='Tipo'
          onConfirm={addNewCategory}
          theme='receipt'
          icon='add'
          receipts={true}
        />
      )}
      {data.map(
        (item, index) => (
          <ReceiptTable
            categoryId={item.id}
            totalPercentage={item.percentage}
            totalSpent={item.total}
            classification={item.classification}
            categoryName={item.name}
            items={item.items}
            key={index}
            className="color-receipt"
            setShowEditItemModal={setShowEditItemModal}
            setShowEditCategoryModal={setShowEditCategoryModal}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            setShowSimpleAddModal={setShowAddItemModal}
            setSelectedCategory={setSelectedCategory}
            setSelectedItem={setSelectedItem}
            getSelectedItems={setSelectedItems}
          />
        )
      )}
      <ButtonContainer>
        <ButtonCategory setShowModal={setShowSimpleAddModal} />
      </ButtonContainer>
    </>
  );
};
