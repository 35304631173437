import React from 'react';
import { LegendCard } from './styles';
import { SubtitleDetail } from '../SubtitleDetail';

export const LegendPotDetail = ({
  budget,
  getValue,
  cutItems,
  handleClick,
  items,
}) => {
  return (
    <LegendCard>
      {items.map(({ key, text, className }) => {
        const value = getValue(key);
        return (
          <SubtitleDetail
            key={key}
            text={text}
            value={cutItems[key] ? 'R$ 0' : value}
            className={`${className} ${cutItems[key] ? 'line' : ''}`}
            onClick={() => handleClick(key)}
          />
        );
      })}
    </LegendCard>
  );
};
