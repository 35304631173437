import React, { useEffect, useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const EditDebtItemModal = ({
  showModal = false,
  setShowModal = () => { },
  selectedItem = null,
  title = 'Editar item',
  icon,
  onConfirm = () => { },
  onCancel = () => { },
  confirmButtonText = 'Confirmar',
  cancelButtonText = 'Cancelar',
  theme,
  allCategories = [],
}) => {
  const [name, setName] = useState(selectedItem?.name || '');
  const [item, setItem] = useState(selectedItem?.item_id);
  const [classification, setClassification] = useState(selectedItem?.category.id);
  const [installmentValue, setInstallmentValue] = useState(
    selectedItem?.value?.raw || 0
  );
  const [currentInstallment, setCurrentInstallment] = useState(
    selectedItem?.current_installment
  );
  const [totalInstallments, setTotalInstallments] = useState(
    selectedItem?.total_installments
  );
  const [itemsOptions, setItemsOptions] = useState([]);

  const clickCancel = () => {
    if (icon === 'add') {
      onCancel({
        name,
        value: installmentValue,
        item_id: item,
        category_id: classification,
        currentInstallment: Number(currentInstallment),
        totalInstallments: Number(totalInstallments),
      });
    } else {
      onCancel({
        name,
        value: installmentValue,
        item_id: item,
        category_id: classification,
        currentInstallment: Number(currentInstallment),
        totalInstallments: Number(totalInstallments),
        id: selectedItem?.id
      });
    }
  }

  const getCategoriesClassification = () => {
    return allCategories.map(category => {
      return { label: category.name, value: category.id }
    })
  }

  useEffect(() => {
    setItemsOptions(
      allCategories.flatMap(category => {
        if (category.id === classification) {
          return category.items.map(item => ({
            label: item.name,
            value: item.id,
          }));
        }
        return [];
      })
    );
  }, [classification, allCategories]);


  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal delete"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal delete"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.Dropdown
          className="simple-modal"
          selectOptions={getCategoriesClassification()}
          getDropdownValue={setClassification}
          label="Categoria"
          placeholderSelect={selectedItem?.category.name || ''}
        />
        <Modal.Dropdown
          className="simple-modal after"
          getDropdownValue={setItem}
          label="Item"
          placeholderSelect={selectedItem?.item.name || ''}
          selectOptions={itemsOptions || []}
        />
        <Modal.InputText
          className="simple-modal after"
          label="Nome"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.InputCurrency
          className="simple-modal after"
          label="Valor da parcela"
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
        <Modal.InputNumber
          className="simple-modal after"
          label="Parcela atual"
          getInputValue={setCurrentInstallment}
          defaultValue={currentInstallment}
        />
        <Modal.InputNumber
          className="simple-modal after"
          label="Total de parcelas"
          getInputValue={setTotalInstallments}
          defaultValue={totalInstallments}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onCancel={() => clickCancel()}
        onConfirm={onConfirm}
        className={`simple-modal ${theme}`}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
      />
    </Modal.Container>
  );
};
