import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';
import { NavigationCardBasicStyle } from 'styles/components/NavigationCardBasicStyle';

export const GenericLoading = styled.div`
  display: block;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

  &.semiannual-report-title {
    width: 229px;
    border-radius: 8px;
    height: 40px;
  }

  &.semiannual-report-go-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  &.semiannual-report-months-arrows {
    width: 26px;
    height: 26px;
    border-radius: 8px;
  }

  &.semiannual-report-months-arrows.first {
    margin-right: 24px;
  }

  &.semiannual-report-months-arrows.last {
    margin-left: 24px;
  }

  &.semiannual-report-months {
    height: 88px;
    width: 240px;
    border-radius: 8px;

    @media (max-width: 1760px) {
      width: 210px;
      height: 80px;
    }

    @media (max-width: 1550px) {
      width: 190px;
    }
  }

  &.report-table-modal-icon {
    width: 19px;
    height: 21px;
    border-radius: 4px;
  }

  &.report-table-modal-title {
    width: 85%;
    height: 21px;
    border-radius: 4px;
    margin-left: 8px;
  }

  &.report-table-modal-body {
    width: 100%;
    height: 46px;
    border-radius: 8px;
  }

  &.report-table-modal-body.after {
    margin-top: 24px;
  }

  &.report-table-modal-footer {
    width: 40%;
    height: 46px;
    border-radius: 8px;
  }

  &.goals-panel-title {
    height: 47px;
    width: 75%;
    border-radius: 50px;
  }

  &.goals-panel-searchbar {
    width: 20%;
    height: 47px;
    border-radius: 8px;
  }

  &.budget-card,
  &.spending-card,
  &.goal-panel-navigation-card {
    ${NavigationCardBasicStyle}
  }

  &.goals-panel-dream {
    width: 24%;
    height: 396px;
    margin-top: 32px;
    border-radius: 16px;
    transition: all 0.7s ease-in-out;

    @media (max-width: 1370px) {
      width: 48%;
    }
  }

  &.empty-suitability-questionnaire {
    width: 1600px;
    height: 532px;
    border-radius: 16px;
    margin-top: 34px;
    margin-bottom: 64px;
  }

  &.investment-panel-title {
    width: 279px;
    height: 25px;
    margin-left: 22px;
    border-radius: 16px;
    min-width: 148.8px;
    min-height: 13.3px;

    @media (max-width: 1023px) {
      margin-left: 11.7px;
      border-radius: 8.5px;
    }
  }

  &.investment-panel-go-icon {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    border-radius: 8px;

    @media (max-width: 1023px) {
      width: 13.3px;
      height: 13.3px;
      margin-left: 10.7px;
      border-radius: 4.3px;
    }
  }

  &.investment-panel-table {
    width: 100%;
    height: 444px;
    border-radius: 8px;
    min-width: 826.7px;
    min-height: 237px;

    @media (max-width: 1023px) {
      border-radius: 4.3px;
    }
  }

  &.allocation-card {
    width: 784px;
    height: 512px;
    border-radius: 16px;
    margin-bottom: 35px;
    min-width: 418.1px;
    min-height: 273.1px;

    @media (max-width: 1023px) {
      border-radius: 8.5px;
      margin-bottom: 18.7px;
    }
  }

  &.immediate-liquidity {
    width: 100%;
    height: 823px;
    border-radius: 16px;
    margin-top: 34px;
    margin-bottom: 64px;
    margin-bottom: 43px;
    min-width: 853px;

    @media (max-width: 1023px) {
      width: 853px;
      height: 438.9px;
      border-radius: 8.5px;
      margin-top: 18.1px;
      margin-bottom: 22.9px;
    }
  }
  &.detail-pot {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  &.extract-menu-header {
    width: 100%;
    height: 42px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 36px;
    }
  }

  &.extract-redirect-icons {
    height: 40px;
    width: 40px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      width: 36px;
      height: 36px;
    }
  }

  &.extract-menu-dropdown {
    width: 48.03%;
    height: 42px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 38px;
    }
  }

  &.extract-category-title {
    width: 160px;
    height: 29px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 25px;
    }

    @media (max-width: 1024px) {
      height: 22px;
    }
  }

  &.extract-category-title-text {
    width: 204px;
    height: 18px;
    border-radius: 8px;
    align-self: flex-end;

    @media (max-width: 1440px) {
      height: 14px;
    }
  }

  &.extract-category-title-progressbar {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-top: 8px;

    @media (max-width: 1440px) {
      margin-top: 6px;
    }
  }

  &.extract-category-title-icon {
    width: 20px;
    height: 20px;
    border-radius: 4px;

    @media (max-width: 1440px) {
      width: 16px;
      height: 16px;
    }

    @media (max-width: 1024px) {
      width: 14px;
      height: 14px;
    }
  }

  &.extract-item-progressbar {
    width: 100%;
    height: 14px;
    border-radius: 20px;

    @media (max-width: 1440px) {
      height: 12px;
    }

    @media (max-width: 1024px) {
      height: 10px;
    }
  }

  &.detailed-extract-footer {
    width: 100%;
    height: 48px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 44px;
    }
  }

  &.detailed-extract-item {
    width: 100%;
    height: 32px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 28px;
    }
  }

  &.detailed-extract-item-loading {
    width: 100%;
    height: 32px;
    border-radius: 8px;
    padding: 20px 0;

    @media (max-width: 1440px) {
      height: 28px;
      padding: 16px 0;
    }

    @media (max-width: 1024px) {
      height: 24px;
      padding: 12px 0;
    }
  }

  &.extract-item-body {
    width: 100%;
    height: 14px;
    border-radius: 20px;

    @media (max-width: 1024px) {
      height: 12px;
    }
  }

  &.simple-modal-delete-header {
    width: 100%;
    height: 23px;
    border-radius: 20px;
  }

  &.simple-modal-delete-body {
    width: 90%;
    height: 36px;
    border-radius: 8px;
    margin: 0 auto;
  }

  &.simple-modal-delete-footer {
    width: 20%;
    height: 34px;
    border-radius: 4px;
  }

  &.simple-modal-delete-footer.first {
    width: 20%;
  }

  &.simple-modal-delete-footer.second {
    width: 30%;
  }

  &.simple-modal-edit-item-category {
    width: 100%;
    height: 60px;
    border-radius: 8px;
  }

  &.simple-modal-edit-item-category-group-itens {
    height: 64px;
    border-radius: 8px;
  }

  &.simple-modal-edit-item-category-group-itens.first {
    width: 30%;
  }

  &.simple-modal-edit-item-category-group-itens.second {
    width: 62%;
  }

  &.simple-icon-loading {
    width: 37px;
    height: 37px;
    border-radius: 8px;
  }

  &.simple-row-table-loading {
    height: 44px;
    width: 100%;
    border-radius: 16px;
  }
`;
